import React from "react";
import styles from "./index.module.scss";
import Menu from "../common/menu";
import Foot from "../common/foot";

const activitys = [
  {
    title: "2023湖南行",
    logo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/20230923/logo.jpg",
    introduce: "捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手",
  },
  {
    title: "2023二狗主席",
    logo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/20230606/logo.jpg",
    introduce: "捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手",
  },
  {
    title: "20230903王者归来",
    logo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/20230903/logo.jpg",
    introduce: "捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手",
  },
  {
    title: "20231014喜获二公子",
    logo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/20231014/logo.jpg",
    introduce: "捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手",
  },
  {
    title: "年轻就要造",
    time: "20231118",
    logo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/20231118/20231118.png",
    introduce: "张超先生在正式向大家发出婚礼邀请。",
  },
  {
    title: "期待的1227",
    time: "20231227",
    logo: "https://p3-q.mafengwo.net/s10/M00/85/0E/wKgBZ1ouId6AcuuPAAuyk6ejwGg21.jpeg?imageView2/2/w/1360/q/90%7CimageMogr2/strip/quality/90",
    introduce: "张超先生在正式向大家发出婚礼邀请。",
  },
  {
    title: "计算机基础",
    logo: "https://n.sinaimg.cn/sinakd10114/608/w328h280/20210212/4068-kiweitw6579941.jpg",
    introduce: "捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手捡漏手",
  },
];

const History = () => {
  return (
    <section className={styles.container}>
      <Menu curIndex={2} />
      <section className={styles.activityContainer}>
        {activitys?.map((item, idx) => {
          return (
            <section
              className={styles.activityItem}
              key={idx}
              onClick={() => {
                // setDialogInfo(item);
                // setShowDialog(true);
              }}
            >
              <img className={styles.activityLogo} src={item.logo}></img>
              <section className={styles.activityInfo}>
                <div className={styles.activityTitle}>{item.title}</div>
              </section>
            </section>
          );
        })}
      </section>
      <Foot />
    </section>
  );
};

export default History;
