import React from "react";
import styles from "./index.module.scss";
import hqLogo from "../../../assets/images/hq_icon.png";
const Foot = () => {
  return (
    <section className={styles.contactContainer}>
      <section className={styles.contactAll}>
        <img className={styles.contactLogo} src={hqLogo} />
        <section className={styles.contactInfo}>
          <div>电话：（021）16888888 传真：（021）16888888</div>
          <div>创业计划联系：deals@hq.com</div>
          <div>加入华旗或被投资公司：hr@hq.com</div>
        </section>
      </section>
      <section className={styles.medioContainer}>
        <div>微信</div>
        <div>微博</div>
        <div>头条</div>
        <div>抖音</div>
      </section>
    </section>
  );
};

export default Foot;
