import React, { useRef, useState } from "react";
import styles from "./index.module.scss";
import Menu from "../common/menu";
import Foot from "../common/foot";
import VideoBanner from "../../assets/videos/1626920616576544.mp4";

const members = [
  {
    name: "李康强",
    nickName: "强哥",
    position: "荣耀队长",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/qg.jpg",
    introduce:
      "强哥是球队的创始人之一，效力于球队期间曾10次入选临港全明星，个人荣膺5次常规赛MVP和5个总决赛MVP，球队基石，精神领袖，如果说科比是NBA自律的象征，那么强哥就是球队的自律图腾，日常训练中强哥会严格要求自身，对身体的控制已经达到了令人绝望的程度，信奉越自律，越自由，这种专业的职业态度让人肃然起敬，场上被誉为“球场永动机”，跑动积极，投射精准，防守强悍是所有进攻手的噩梦。\n\n强哥体态雄伟，却内心温柔，对妻子和孩子非常有耐心，最近爱好上了电吹管，以其专注的态度，期待听到他的演奏。",
  },
  {
    name: "范孟汇",
    nickName: "范总",
    position: "球队经理",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/fz.jpg",
    introduce:
      "球队大脑，球风冷静，打球聪明且富有领袖才能，阅读比赛能力强，擅长梳理进攻，盘活全队，意志坚强，精神属性独一档的存在，曾蝉联多届球队MVP，范总是团队篮球的践行者，自带体系，AKA“行走的普林斯顿”，你可能不愿意在乔丹身边打球，但是你肯定愿意在范总身边打球。\n\n范总是一位连续创业者，热衷创业，凡夫迷失于当下，范总却从来都清楚自己真正想要的是什么，足够自信，有着能收拾一切残局的勇气，作为球队的另一位天使投资人，范总品质谦和低调，知进退，识大体，球队人生导师；酒量大，酒品好，可惜从未见其醉之真容。",
  },
  {
    name: "张伟华",
    nickName: "华仔",
    position: "创始合伙人",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/hg.jpg",
    introduce:
      "伟华先生是华旗商贸公司的联合创始人，福布斯晋江富豪排行榜排名第1888位，业界有“杨芬拥有者”的响亮称号，作为公司的天使投资人，华哥乐于为创业企业的创立及成长提供帮助，作为国内知名企业家与天使投资人。伟华先生先后在多家世界名企担任高管，取得了优异的成绩。\n\n华哥在2021年遭遇了职业生涯中最严重的一次伤病—膝盖骨折，这成为了他一生挥之不去的梦魇，经历大伤后的华哥，依靠惊人的毅力和优秀的意志品质在伤病中恢复过来，球风变得更加强硬高效，感叹球场上的“那个男人”回来了！",
  },
  {
    name: "石君",
    nickName: "Daniel",
    position: "失误万花筒",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/sj.jpg",
    introduce:
      "王子全称—阿依土鳖王子，AKA“球队黑洞”“球场癌症”“老板娘杀手”“浦江唐正东”，技术粗糙，球风肮脏，有着巨大的身躯却畏惧对抗，篮下经常空蓝不进，但喜欢在挡拆时下黑手，事后还问人家有没有事，职业生涯垫脚造成对方球员重伤11人，有2人直接结束了职业生涯，曾被湖南工程报评出的“21世纪最卑鄙的篮球运动员排行榜”中排名第一，封号“残暴王子”。\n\n王子没有诚信，经常缺席球队训练，没有时间观念，迟到1小时是常事，喜欢喝酒，酒量深不见底，传说他与恶魔做过交易，他的良心有多坏他的酒量就有多大，这就解释了为什么王子有了一个吃喝嫖赌万毒不侵的身体；爱好广泛，喜欢骑马、做家务、酒后裸睡、演戏，PUA老婆孩子。",
  },
  {
    name: "陈逾立",
    nickName: "板栗",
    position: "随缘射手",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/bl.jpg",
    introduce: "知らない人",
  },
  {
    name: "陈超",
    nickName: "大超",
    position: "A队队长",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/dc.jpg",
    introduce:
      "超哥是球队的创始人之一，效力于球队期间曾10次入选川沙全明星，个人荣膺5次常规赛MVP和5个总决赛MVP，球队得分王，天赋怪，内线制霸，外线投篮精准，在很多关键场次中多次投中关键球拯救球队，令人惊讶的是，在防守端他还能锁死对手箭头人物，是球队的关键先生。\n\n超哥喜欢喝酒，酒量惊人，能一直喝，酒局每场必到，传说他在一场与罗永浩的酒局中为老罗指点迷津，至此，老罗开启直播带货的“真还传”逆袭之旅，从此坊间更是留下了关于“陈院长”的传说，世人都知道罗永浩水深，但是不知道陈院长的量有多深！",
  },
  {
    name: "孙帅",
    nickName: "帅子",
    position: "浴室公关",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/sz.jpg",
    introduce:
      "帅子是一个天赋出众的球员，年轻时球风也是上天入地的存在，可惜，上帝总是嫉妒天才，在职业生涯中遭遇了一次毁灭性的伤病，这次伤病几乎毁掉了帅子整个职业生涯，你永远不知道他经历过什么，走过了怎样的灰暗，几经挣扎彷徨，刻苦的康复训练，最终他终于从伤病的阴影中走了出来，很少有球队愿意给一位重伤的球员定薪合同，但是华旗商贸给了他足够的尊重，因为他值得。\n\n孙帅先生性格温润如玉，五官清秀，异性缘好，年轻时曾居住在唐镇，唐镇至今都有他的传说，那段岁月，他不敢去外面的世界，因为唐镇和川沙有姑娘满世界在找他；孙帅先生歌喉婉转，成名曲慢摇版最炫民族风至今无敢超越，p.s 喜欢丢",
  },
  {
    name: "薛帅旗",
    nickName: "小旗",
    position: "B队队长",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/xq.jpg",
    introduce:
      "帅旗先生是华旗商贸公司的联合创始人，效力于球队期间曾10次入选张江全明星，个人荣膺5次常规赛MVP和5个总决赛MVP，球队ACE，技术扎实，球风朴实无华，学院派代表，大学期间曾入选过4届CUBA全明星阵容，鹤壁名人堂球员，如今的CUBA赛场依旧流传着“顺境找帅旗，逆境靠帅旗，绝境看帅旗”的传说。\n\n旗总为人踏实耐心，看问题有境界，做事情有主见，在五大三粗的外表下藏着一颗七窍玲珑心，华旗商贸公司自成立以来，在金融、人工智能、互联网行业拓展多元化的产品体系，以一流的技术定义华旗商贸的企业精神，业务更是遍及100多个国家与地区，成为中国企业智能时代的引领者。",
  },
  {
    name: "李雄风",
    nickName: "雄风",
    position: "球队ACE",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/xf.jpg",
    introduce:
      "ACE，AKA“一打五大师”“单挑王”“0”号战术的唯一执行者，球风狂野，有侵略性，富有想象力，不适合团队协作，能独立解决问题，取得胜利，雄风的加入彻底改变了团队对传统篮球是五个人的运动的认知，从一开始的寻求他融入球队体系，到现在的让他自带体系，球队逐渐找到了对他的使用说明书，我们团队也完成了从质疑他到理解他再到成为他的蜕变，倔强的“益阳精神”总是能在关键时刻指引他投出关键的一球，当球离开指尖，自信回头是他最好的个人标签。\n\n个性张扬，好奇心重，酒量浅，喝完酒必须睡觉，长着一张极具代表性的东方男人脸，轮廓深邃，充满性张力，曾经的太平鸟品牌形象代言人，女人缘极佳，自带引力，喜欢消费窗帘。",
  },
  {
    name: "张超",
    nickName: "小超",
    position: "夜场公关",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/xc.jpg",
    introduce:
      "低调的内蒙古科尔沁王子，蒙古名为“布日固德”，寓意雄鹰，草原雄鹰飞过处，俯查所视皆为领土，辽阔的科尔沁草原从不缺乏果敢坚毅的男子，而他更是男人中的男人，球队新星，防守积极，跑位飘忽，最近更是开发出了一手稳定投射，为球队提供稳定输出，随着实力的暴涨，对自身在球队中的地位有了新的要求，比赛中想获取更多的球权，与此同时，希望球队可以为自己提供一份顶薪合同。\n\n小超为人低调随和，酒量大，但是以内蒙人的标准，基本只能坐小孩一桌，爱笑，笑起来很好看，曾经不止一次因为一脸天真的微笑，被未成年少女搭讪。\n特别喜欢做家务。",
  },
  {
    name: "尹腾翔",
    nickName: "腾翔",
    position: "街球队队长",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/tx.jpg",
    introduce:
      "街球手，球风华丽，能拉杆绝不上篮，自信是球队最好的得分手，大学期间曾入选过CUBA西北赛区最佳阵容，热衷与雄风单挑，实力与雄风伯仲之间，多次在关键场次绝杀对手，不足是体力槽偏短，续航能力有待提高。\n\n腾翔先生被誉为华旗金融巨鳄， 眼光独到，对复杂多变的金融市场有自己敏锐的判断，从发现机会，调研分析，判断机会，交易执行，加大仓位，迭代分析，坚守决定，最终获得丰厚收益，一切操作尽在他运筹之中，腾翔先生也是一位优秀的酒文化推广师，酒量大，酒令多，曾几何时，“腾”是一个计酒量单位，青春的小酒桌围坐到清晨是他的平生所愿。",
  },
  {
    name: "谢飞洋",
    nickName: "飞洋",
    position: "物料总监",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/fy.jpg",
    introduce:
      "才华横溢的球员，河南欧文，球风潇洒，擅长空中作业，打球有灵性，尤擅突破，全明星级别小前锋，飞阳是典型的天赋型选手，多少人梦想获得的天赋他已然拥有，几乎不需要刻苦训练就可以达到一个普通人无法企及的球员水平。\n\n飞阳爱好喝酒，酒量大，雄风克星，酒桌日常碾压雄风，跟河南张天爱恋爱并步入婚姻殿堂，婚姻美满，顾家爱妻，羡煞旁人",
  },
  {
    name: "李聪",
    nickName: "聪弟",
    position: "-U30 ACE",
    photo: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/members/lc.jpg",
    introduce:
      "篮球世家，有着令人艳羡的静态天赋，臂展惊人，李聪在加入球队以后以一种难以置信的速度成长，如今篮球水平已小有所成，要知道，他所在的环境是不利于培养篮球运动员的，生活在酒池肉林中，还有一个试图毁掉他篮球梦想的哥哥，倔强的李聪用一种不可思议的坚毅成长着，未来之星，梦想可期；李聪先生酒量大，据不权威统计，酒量大概是雄风哥哥的10倍左右，不说狠话，只喝劲酒，出道以来，就算喝吐也从来不怂，球队地位直逼家兄，愿他未来星途坦荡。",
  },
];

const Team = () => {
  const [showDialog, setShowDialog] = useState(false);

  const [dialogInfo, setDialogInfo] = useState(members[0]);

  const videoRef = useRef();
  const [isPlay, setIsPlay] = useState(false);

  return (
    <section className={styles.container}>
      <Menu curIndex={1} />

      <section className={styles.titleInfoContainer}>
        <video
          className={styles.videoBanner}
          ref={videoRef}
          src={VideoBanner}
          onClick={() => {
            if (isPlay) {
              setIsPlay(false);
              videoRef.current.pause();
            } else {
              setIsPlay(true);
              videoRef.current.play();
            }
          }}
        />
        <section className={styles.texts}>
          <div className={styles.title}>团队成员</div>
          <div className={styles.desc}>
            人最宝贵的东西是生命。每个人只有一次生命。因此,一个人的一生应该这样度过:当他回顾已逝年华时不因虚度时光而悔恨,也不因一事无成而羞愧;
            这样,在他即将离开人世的时候,就可以坦然地说:“我把整个生命和全部精力,都奉献给了人世间最美好的事---家庭、事业和朋友！“。
          </div>
        </section>
      </section>

      <section className={styles.imagesContainer}>
        {members?.map((item, idx) => {
          return (
            <section
              className={styles.memberItem}
              key={idx}
              onClick={() => {
                setDialogInfo(item);
                setShowDialog(true);
              }}
            >
              <img className={styles.memberPhoto} src={item.photo}></img>
              <span className={styles.memberMask}></span>
              <section className={styles.memberInfo}>
                <div className={styles.memberName}>{item.nickName}</div>
                <div className={styles.memberPosition}>{item.position}</div>
              </section>
            </section>
          );
        })}
      </section>
      <Foot />

      <div
        className={`${styles.maskAll} ${showDialog ? "" : styles.hide}`}
        onClick={() => {
          setShowDialog(false);
        }}
      ></div>

      <section className={`${styles.dialog} ${showDialog ? "" : styles.hide}`}>
        <img className={styles.dialogPic} src={dialogInfo?.photo} />
        <section className={styles.dialogInfo}>
          <div className={styles.name}>{dialogInfo?.name}</div>
          <div className={styles.position}>{dialogInfo?.position}</div>
          <div className={styles.introduce}>{dialogInfo?.introduce}</div>
        </section>
      </section>
    </section>
  );
};

export default Team;
