import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import bg from "../../assets/images/bg.jpeg";

import Menu from "../common/menu";
import Foot from "../common/foot";

import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import {
  navigation,
  pagination,
  EffectCreative,
  Scrollbar,
  A11y,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import imgArrow from "../../assets/images/arrow.png";
import { useNavigate } from "react-router-dom";

const banners = [
  {
    url: "https://members-of-hq.oss-cn-shanghai.aliyuncs.com/20231118/20231118.png",
    target: "../activityDetails",
    title: "",
    desc: "",
  },
];

const Home = () => {
  const refSwiper = useRef();
  var curBannerIndex = useState(1);
  const navigate = useNavigate();

  const [swiper, setSwiper] = useState(null);

  return (
    <section className={styles.container}>
      <Menu curIndex={0} />
      <section className={styles.bannerContainer}>
        <div
          className={`${banners.length == 1 ? styles.hide : ""} ${styles.prev}`}
          onClick={() => {
            swiper.slidePrev();
          }}
        >
          <img src={imgArrow} />
        </div>

        <Swiper
          ref={refSwiper}
          spaceBetween={0}
          slidesPerView="auto"
          initialSlide={0} // 初始化显示哪一个
          loop={true} // 是否循环
          onInit={(ref) => {
            setSwiper(ref);
          }}
          threshold={40}
        >
          {banners?.map((item, idx) => {
            return (
              <SwiperSlide key={idx}>
                <div
                  className={styles.bannerItemContainer}
                  onClick={() => {
                    navigate(item.target);
                  }}
                >
                  <img
                    className={`${idx == curBannerIndex ? styles.asdas : ""} ${
                      styles.bannerItem
                    }`}
                    src={item.url}
                  />
                  <section className={styles.bannerItemInfo}>
                    <div className={styles.bannerItemTitle}>{item.title}</div>
                    <div className={styles.bannerItemDesc}>{item.desc}</div>
                  </section>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div
          className={`${banners.length == 1 ? styles.hide : ""} ${styles.next}`}
          onClick={() => {
            swiper.slideNext();
          }}
        >
          <img src={imgArrow} />
        </div>
      </section>

      <section className={styles.tipContainer}>
        <img src="https://shunwei.com/wp-content/themes/shunwei/img/sw-home-bg-1.png" />
        <div className={styles.tipTitle}>人因梦想而伟大</div>
        <div className={styles.tipDesc}>
          从梦想到现实的过程中，我们希望借助互联网和高科技完成创业梦想
          <div>共同携手创造令人尊敬的伟大企业！</div>
        </div>
      </section>

      <section className={styles.tip2Container}>
        <img
          className={styles.tip2Pic1}
          src="https://shunwei.com/wp-content/themes/shunwei/img/sw-home-bg-3.jpg"
        />
        <img
          className={styles.tip2Pic}
          src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fsafe-img.xhscdn.com%2Fbw1%2Fe9cc60da-7783-426b-94ee-0c0ed0e9f2df%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fsafe-img.xhscdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1700813454&t=dbebe7f67cdb2be461e3dd6006cd4778"
        />
        <div className={styles.tip2Title}>
          「我们希望通过技术和经验的帮助，
          <div>
            <span style={{ marginRight: "30px" }}></span>
            与家人们共同创造受人尊敬的第三收入！」
          </div>
        </div>
      </section>
      <Foot />
    </section>
  );
};

export default Home;
