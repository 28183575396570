import React from "react";
import styles from "./index.module.scss";
import hqLogo from "../../../assets/images/hq_icon.png";
import { useNavigate } from "react-router-dom";

const menus = [
  { title: "首页", path: "/" },
  { title: "管理团队", path: "../team" },
  { title: "历史动态", path: "../history" },
];

const Menu = (props) => {
  const navigate = useNavigate();

  const curIndex = props.curIndex;

  const menuOnClick = (index) => {
    if (index == curIndex) return;
    console.log(menus[index].path);
    navigate(menus[index].path);
  };

  return (
    <section className={styles.container}>
      <section className={styles.menusContainer}>
        {menus?.map((item, idx) => {
          return (
            <div
              className={`${styles.menuItem} ${
                idx == curIndex ? styles.menuSelected : ""
              }`}
              key={idx}
              onClick={() => {
                menuOnClick(idx);
              }}
            >
              {item.title}
            </div>
          );
        })}
      </section>
      <img className={styles.icon} src={hqLogo} />
    </section>
  );
};

export default Menu;
