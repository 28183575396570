import React from "react";
import styles from "./index.module.scss";

const activityInfo = [
  {
    // title: "2023.11.18号 日常赛",
    // time: "2023.11.18 15:00-17:00",
    // address: "C31篮亭公园 4号场地",
    // desc: "不受伤为前提，尽情挥洒青春",
  },
  // {
  //   title: "2023.11.18号 赛后餐",
  //   time: "2023.11.18 18:00-睡前",
  //   address: "",
  //   desc: "有重要事情宣布",
  // },
];

const ActivityDetails = () => {
  return (
    <section className={styles.container}>
      <img
        className={styles.bg}
        src="https://members-of-hq.oss-cn-shanghai.aliyuncs.com/20231118/WX20231113-143331.jpg"
      />
    </section>
  );
};

export default ActivityDetails;
