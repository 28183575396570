import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Team from "./pages/team";
import History from "./pages/history";
import ActivityDetails from "./pages/activityDetails";

export const RouteList = (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/team" element={<Team />} />
    <Route path="/history" element={<History />} />
    <Route path="/activityDetails" element={<ActivityDetails />} />
  </Routes>
);
